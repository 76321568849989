import axios from "@axios"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchUsageDetails: (context, {
            startDate,
            endDate,
            customerId,
            page,
            perPage
        }) => new Promise((resolve, reject) => axios
            .get(`/api/v1/Asset/reports/usage?StartDate=${startDate}&EndDate=${endDate}&CustomerId=${customerId}&Page=${page}&PerPage=${perPage}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        ),
        exportUsageToExcel: (ctx, {startDate, endDate, customerId}) => new Promise((resolve, reject) => {
            axios({
                url: `/api/v1/queries/fleet/exportusageexcel?StartDate=${startDate}&EndDate=${endDate}&CustomerId=${customerId}`,
                method: 'GET',
                responseType: 'blob', // important
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
}