<template>
  <b-card>
    <b-card-header>
      <div class="d-flex w-100 justify-content-between align-items-center">
        <b-card-title class="flex-fill">{{ $t('Usage') }}</b-card-title>

        <b-button :disabled="isExporting" @click="exportToExcel" variant="info" class="d-flex align-items-center">
          <b-spinner v-if="isExporting" small/>
          <feather-icon v-else icon="DownloadIcon"/>
          <span class="align-middle ml-50">{{ $t('Export Sheet') }}</span>
        </b-button>
      </div>
    </b-card-header>
    <b-row class="mb-3 ml-50">
      <b-col cols="12" md="5" lg="4" class="mb-md-0 mb-3">
        <h5 class="font-weight-bold">{{ $t('Choose the Date') }}</h5>

        <div class="d-flex">
          <flat-pickr id="date-range-picker" v-model="dateRange" :config="flatpickrConfig"
                      @on-change="ensureAllowedDateRange" class="form-control flat-picker"/>
          <b-button @click="updateReport" size="sm" variant="primary" style="border-radius: 0 0.357rem 0.357rem 0;">
            <feather-icon
                icon="ArrowRightIcon"
                size="16"
            />
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" md="5" class="mb-md-0 mb-2">
        <h5 class="font-weight-bold">{{ $t('Customer') }}</h5>
        <div class="d-flex">
          <vue-autosuggest v-model="searchCustomer" :suggestions="filteredOptions" :input-props="inputProps"
                           @selected="selectHandler" @input="onInputChange" class="flex-fill">
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              <div v-show="suggestion.item.ownerName">
                <span class="text-muted">{{ $t('Belongs to: ') }}</span>
                <span class="font-weight-bold"> {{ suggestion.item.ownerName }} </span>
              </div>
            </template>
          </vue-autosuggest>

          <b-button :disabled="searchCustomer.length === 0" @click="clearInput" size="sm"
                    style="border-radius: 0 0.357rem 0.357rem 0;">
            <feather-icon
                icon="XIcon"
                size="16"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-3 ml-25">
      <b-col cols="12" md="12" class="mb-md-0 mb-3">
        <b-table ref="reportTable" :items="fetchUsageDetails" :busy="state === ReportState.LOADING"
                 responsive :fields="tableColumns" primary-key="id"
                 show-empty :empty-text="emptyText" tbody-tr-class="align-items-center text-center"
                 thead-tr-class="align-items-center text-center"
                 class="position-relative">
          <template #table-busy>
            <b-spinner variant="primary" label="Carregando dados" class="m-2" style="width: 3rem; height: 3rem;" />
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            {{ $t('Showing') }} {{ metadata.from }} {{ $t('to') }} {{ metadata.to }} {{ $t('of') }} {{
              metadata.of
            }} {{ $t('entries') }}
          </span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="perPage" first-number last-number
                            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>

            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BDropdownItem,
  BPagination,
  BRow,
  BSpinner,
  BTable
} from "bootstrap-vue"
import store from "@/store"
import {onUnmounted} from "@vue/composition-api"
import useUsageReport from "@/views/main/reports/useUsageReport"
import flatPickr from 'vue-flatpickr-component'
import {VueAutosuggest} from "vue-autosuggest"
import customerStoreModule from "@/views/main/customer/customerStoreModule"
import reportsStoreModule from "@/views/main/reports/reportsStoreModule"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ReportState} from "@/views/main/reports/utils";

export default {
  computed: {
    ReportState() {
      return ReportState
    }
  },
  components: {
    BDropdownItem,
    BButton,
    BCardBody,
    BPagination,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BSpinner,
    flatPickr,
    VueAutosuggest,
    ToastificationContent
  },
  data() {
    return {
      emptyText: this.$t('No matching records found'),
      flatpickrConfig: {
        maxDate: new Date(),
        mode: 'range',
        dateFormat: "d/m/Y",
      },
      searchCustomer: '',
      datasuggest: [],
      filteredOptions: [],
      isExporting: false,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control auto-suggestions-box',
        placeholder: "Cliente",
      },
    }
  },
  methods: {
    ensureAllowedDateRange(dates) {
      const formattedDates = this.formatDateRange(dates, "date")

      if (formattedDates.length > 1) {
        const minAllowedDate = new Date(formattedDates[1])
        minAllowedDate.setDate(minAllowedDate.getDate() - 31)

        if (formattedDates[0] < minAllowedDate) {
          this.showSnackbar({
            title: this.$t('Max interval one month'),
            icon: 'InfoIcon',
          })

          this.dateRange = [this.formatDate(minAllowedDate), this.formatDate(formattedDates[1])].join(" to ")
        }
      }
    },
    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchCustomer = option.item.name
      this.customerId = option.item.id
    },
    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {
        this.customerId = ''
        return
      }

      store.dispatch('app-customer/fetchCustomers', {
        q: this.searchCustomer,
        perPage: 5,
        page: 1,
      }).then(response => {
        this.filteredOptions = [{
          data: response.data.data,
        }]
      }).catch(console.log)
    },
    updateReport() {
      this.reportTable.refresh()
    },
    showSnackbar(config = {title: '', icon: '', variant: 'info'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: config.title,
          icon: config.icon,
          variant: config.variant,
        },
      })
    },
    dateFromString(string) {
      const [date, month, year] = string.split("/")
      return new Date(year, month - 1, date)
    },
    formatDateRange(dateRange, type = "string") {
      const stringValues = typeof dateRange === "string"
          ? dateRange.split(" to ")
          : dateRange.map(date => this.formatDate(date))

      if (type === "string") return stringValues
      return stringValues.map(this.dateFromString)
    },
    async exportToExcel() {
      if (this.isExporting) return
      this.isExporting = true

      const formattedDates = this.formatDateRange(this.dateRange)

      await store.dispatch('reports/exportUsageToExcel', {
        startDate: formattedDates[0],
        endDate: formattedDates[1],
        customerId: this.customerId,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.$t('Usage')} (${formattedDates.join(" - ")}).xls`)
        document.body.appendChild(link)
        link.click()
        this.isExporting = false
      }).catch(err => {
        console.log(err)
        this.isExporting = false

        this.showSnackbar({
          title: this.$t('Error exporting report'),
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        })
      })
    },
    clearInput() {
      this.searchCustomer = ''
      this.onInputChange('')
    }
  },
  watch: {
    state: function (val) {
      if (val === ReportState.FAILURE) this.showSnackbar({
        title: this.$t('Error fetching report'),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      })
    }
  },
  setup() {
    const REPORTS_STORE_MODULE_NAME = 'reports'
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportsStoreModule)
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchUsageDetails,
      dateRange,
      customerId,
      currentPage,
      perPage,
      state,
      reportTable,
      totalItems,
      tableColumns,
      formatDate,
      metadata
    } = useUsageReport()

    return {
      fetchUsageDetails,
      dateRange,
      customerId,
      currentPage,
      perPage,
      state,
      reportTable,
      totalItems,
      tableColumns,
      formatDate,
      metadata,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.auto-suggestions-box, .flat-picker {
  border-radius: 0.357rem 0 0 0.357rem;
}
</style>