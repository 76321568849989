import store from '@/store'
import {computed, ref, watch} from "@vue/composition-api"
import {ReportState} from "@/views/main/reports/utils"

export default function useUsageReport() {
    const state = ref(ReportState.SUCCESS)
    const reportTable = ref(null)
    const totalItems = ref(0)

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const dateRange = ref([yesterday, new Date()])
    const customerId = ref("")
    const currentPage = ref(1)
    const perPage = ref(50)

    const tableColumns = [
        {key: 'identifier', label: 'Identificador'},
        {key: 'lowerHourmeter', label: 'Horímetro inicial'},
        {key: 'lowerHodometer', label: 'Hodômetro inicial'},
        {key: 'upperHourmeter', label: 'Horímetro final'},
        {key: 'upperHodometer', label: 'Hodômetro final'},
        {key: 'hourmeterDifference', label: 'Horas trabalhadas'},
        {key: 'hodometerDifference', label: 'Quilômetros rodados'},
    ]

    const metadata = computed(() => {
        const localItemsCount = reportTable.value ? reportTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalItems.value,
        }
    })

    watch([currentPage, perPage, customerId], () => reportTable.value.refresh())

    const formatDate = date => {
        const month = String(date.getMonth() + 1)
        const formattedMonth = month.length === 1 ? '0' + month : month

        return `${date.getDate()}/${formattedMonth}/${date.getFullYear()}`;
    }

    const fetchUsageDetails = (_, callback) => {
        state.value = ReportState.LOADING
        const dates = typeof dateRange.value === "string"
            ? dateRange.value.split(" to ")
            : dateRange.value.map(date => formatDate(date))

        store.dispatch("reports/fetchUsageDetails", {
            startDate: dates[0],
            endDate: dates[1],
            customerId: customerId.value,
            page: currentPage.value,
            perPage: perPage.value,
        }).then(response => {
            state.value = ReportState.SUCCESS
            totalItems.value = response.data.totalItems

            const report = response.data.data
            callback(report)
        }).catch(err => {
            state.value = ReportState.FAILURE
            console.error(err)
            callback()
        })
    }

    return {
        fetchUsageDetails,
        dateRange,
        customerId,
        currentPage,
        perPage,
        reportTable,
        totalItems,
        tableColumns,
        formatDate,
        state,
        metadata
    }
}